export function isValueNotEmpty(value) {
    return value !== undefined && value !== "" && value != null;
}

export function isDropDownComponent(type, select_type) {
    return (
        (type === "select" && select_type === "multiple") ||
        (type === "autocomplete" && select_type === "multiple")
    );
}

export function isFormValidate(form) {
    return form.validate()
}

export function formReset(form) {
    return form.reset()
}

export function toEnglishNumber(strNum) {
    var ar = '٠١٢٣٤٥٦٧٨٩'.split('');
    var en = '0123456789'.split('');
    strNum = strNum.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (x) => {
        if (ar.indexOf(x) > -1) {
            return en[ar.indexOf(x)]
        }
    });
    return strNum;

}

export function download(name, path, type = 'pdf') {
    return axios({
        url: 'general/download', // File URL Goes Here
        method: 'POST',
        data: { path: path },
        responseType: 'blob',
    }).then((res) => {
        const url = URL.createObjectURL(
            new Blob([res.data]
            )
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            name + "_" + new Date().toLocaleString() + "." + type
        );
        document.body.appendChild(link);
        link.click();
    });
}
